<script setup>
import { useRoute } from 'vue-router';
import { useLenis } from '@/components/scroll/Lenis.js';

const lenis = useLenis();
defineProps({
    colors: {
        type: Object,
        default: () => {},
    },
});
const route = useRoute();
const onClick = () => {
    if (route.meta.name === 'home') lenis.value.scrollTo(0);
};
</script>

<template>
    <div class="LogoLetter fixed left-1/2 top-0 h-14 w-28 -translate-x-1/2 xl:h-20 xl:w-40">
        <router-link
            class="block h-full w-full"
            :to="{ name: 'home.' + lang }"
        >
            <div
                class="LogoLetter__content absolute bottom-0 h-full w-full overflow-hidden"
                v-on:click="onClick"
            >
                <svg
                    class="absolute bottom-0 left-0 h-14 w-28 xl:h-20 xl:w-40"
                    viewBox="0 0 180 90"
                >
                    <g
                        :class="{
                            'text-dark-blue': colors.bg == 'dark-blue',
                            'text-light-blue': colors.bg == 'light-blue',
                            'text-light-red': colors.bg == 'light-red',
                            'text-dark-red': colors.bg == 'dark-red',
                            'text-dark-yellow': colors.bg == 'dark-yellow',
                            'text-light-yellow': colors.bg == 'light-yellow',
                            'text-dark-yellow': colors.bg == 'dark-yellow',
                            'text-light-green': colors.bg == 'light-green',
                            'text-dark-green': colors.bg == 'dark-green',
                            'text-light-orange': colors.bg == 'light-orange',
                            'text-dark-orange': colors.bg == 'dark-orange',
                            'text-white': colors.bg == 'white',
                        }"
                    >
                        <path
                            class="fill-current transition duration-1000"
                            d="M180,0A90,90,0,0,1,0,0Z"
                        />
                    </g>
                    <g
                        :class="{
                            'text-dark-blue': colors.txt == 'dark-blue',
                            'text-light-blue': colors.txt == 'light-blue',
                            'text-light-red': colors.txt == 'light-red',
                            'text-dark-red': colors.txt == 'dark-red',
                            'text-dark-yellow': colors.txt == 'dark-yellow',
                            'text-light-yellow': colors.txt == 'light-yellow',
                            'text-dark-yellow': colors.txt == 'dark-yellow',
                            'text-light-green': colors.txt == 'light-green',
                            'text-dark-green': colors.txt == 'dark-green',
                            'text-light-orange': colors.txt == 'light-orange',
                            'text-dark-orange': colors.txt == 'dark-orange',
                            'text-white': colors.txt == 'white',
                        }"
                    >
                        <path
                            class="fill-current transition duration-1000"
                            d="M84.8,33.92a4.94,4.94,0,0,0,9.87,0Z"
                        />
                        <path
                            class="fill-current transition duration-1000"
                            d="M94.22,65.31h-9V41.43h9Z"
                        />
                        <path
                            class="fill-current transition duration-1000"
                            d="M113.15,33.23a16.51,16.51,0,0,1,12.14,4.71,15.63,15.63,0,0,1,4.8,11.68,15.81,15.81,0,0,1-4.8,11.72,18.21,18.21,0,0,1-24.33,0,15.81,15.81,0,0,1-4.75-11.72A15.63,15.63,0,0,1,101,37.94,16.65,16.65,0,0,1,113.15,33.23Zm0,24.05A7,7,0,0,0,118.51,55a8,8,0,0,0,0-10.81,7.49,7.49,0,0,0-10.72,0,8,8,0,0,0,0,10.81A7,7,0,0,0,113.15,57.28Z"
                        />
                        <path
                            class="fill-current transition duration-1000"
                            d="M78.88,48.74a8.25,8.25,0,0,1,4.82,7.63c0,5.09-4.39,8.94-10.19,8.94H55.4V33.92H73.22c5.57,0,9.63,3.39,9.63,8a8,8,0,0,1-4,6.78Zm-4.15,6.6a2.83,2.83,0,0,0-3.1-2.84h-7v5.71h7A2.83,2.83,0,0,0,74.73,55.34Zm-.5-12a2.66,2.66,0,0,0-2.93-2.74H64.6v5.47h6.7a2.66,2.66,0,0,0,2.93-2.73Z"
                        />
                        <path
                            class="fill-current transition duration-1000"
                            d="M68,27.86l1.38,1.7-2.87,2.08-1.71-2.08a7.45,7.45,0,0,1-2.17.28,7.68,7.68,0,0,1-5.6-2.22,7.47,7.47,0,0,1-2.25-5.5,7.39,7.39,0,0,1,2.25-5.48,8.13,8.13,0,0,1,11.21,0,7.41,7.41,0,0,1,2.27,5.48A7.44,7.44,0,0,1,68,27.86Zm-9.49-5.74a4.29,4.29,0,0,0,1.18,3.05,4.09,4.09,0,0,0,5.89,0,4.29,4.29,0,0,0,1.18-3.05,4.21,4.21,0,0,0-1.18-3,4,4,0,0,0-5.89,0A4.21,4.21,0,0,0,58.55,22.12Z"
                        />
                        <path
                            class="fill-current transition duration-1000"
                            d="M82.28,29.54H78.74v-.7a4.68,4.68,0,0,1-2.9,1,4.32,4.32,0,0,1-3.27-1.31A5,5,0,0,1,71.32,25V19h3.54v5.49c0,1.47.74,2.23,1.94,2.23a2.63,2.63,0,0,0,1.94-1.05V19h3.54Z"
                        />
                        <path
                            class="fill-current transition duration-1000"
                            d="M104.7,20.35a5.38,5.38,0,0,1,1.55,3.91,5.47,5.47,0,0,1-1.55,3.95A4.92,4.92,0,0,1,101,29.8,5.41,5.41,0,0,1,98.12,29v.52H94.56V14.6h3.56v5a5.33,5.33,0,0,1,2.88-.79A4.92,4.92,0,0,1,104.7,20.35Zm-1.92,3.91a2.63,2.63,0,0,0-.72-1.86,2.27,2.27,0,0,0-1.76-.76,2.79,2.79,0,0,0-2.18,1v3.32a2.78,2.78,0,0,0,2.18,1A2.52,2.52,0,0,0,102.78,24.26Z"
                        />
                        <path
                            class="fill-current transition duration-1000"
                            d="M117.8,25.28h-7.53A2.22,2.22,0,0,0,112.65,27a1.78,1.78,0,0,0,1.61-.77h3.51c-.52,2.18-2.55,3.56-5.17,3.56a5.8,5.8,0,0,1-4.19-1.55,5.28,5.28,0,0,1-1.59-4,5.36,5.36,0,0,1,1.55-4,5.61,5.61,0,0,1,4.1-1.55,5.36,5.36,0,0,1,3.86,1.48A5,5,0,0,1,117.86,24,9.59,9.59,0,0,1,117.8,25.28Zm-7.55-2.07h4.29a1.9,1.9,0,0,0-2.09-1.77A2.07,2.07,0,0,0,110.25,23.21Z"
                        />
                        <path
                            class="fill-current transition duration-1000"
                            d="M93.86,25.28H86.33A2.24,2.24,0,0,0,88.71,27a1.79,1.79,0,0,0,1.62-.77h3.51c-.52,2.18-2.55,3.56-5.17,3.56a5.78,5.78,0,0,1-4.19-1.55,5.28,5.28,0,0,1-1.59-4,5.36,5.36,0,0,1,1.55-4,5.6,5.6,0,0,1,4.1-1.55A5.38,5.38,0,0,1,92.4,20.2,5.05,5.05,0,0,1,93.93,24,9.21,9.21,0,0,1,93.86,25.28Zm-7.55-2.07h4.3a1.91,1.91,0,0,0-2.09-1.77A2.09,2.09,0,0,0,86.31,23.21Z"
                        />
                        <path
                            class="fill-current transition duration-1000"
                            d="M126.33,25.2h3.33a4.7,4.7,0,0,1-1.72,3.31,5.55,5.55,0,0,1-3.71,1.27,5.76,5.76,0,0,1-4.14-1.55,5.24,5.24,0,0,1-1.64-4,5.15,5.15,0,0,1,1.64-3.95,5.77,5.77,0,0,1,4.14-1.57A5.56,5.56,0,0,1,127.94,20a4.68,4.68,0,0,1,1.72,3.3h-3.33a2,2,0,0,0-2.08-1.59,2.34,2.34,0,0,0-2.42,2.53,2.36,2.36,0,0,0,2.42,2.55A2,2,0,0,0,126.33,25.2Z"
                        />
                        <polygon
                            class="fill-current transition duration-1000"
                            points="86.92 17.39 88.31 14.6 91.96 14.6 90.22 17.39 86.92 17.39"
                        />
                    </g>
                </svg>
            </div>
        </router-link>
    </div>
</template>
