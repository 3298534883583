import { computed, watch, ref } from 'vue';
import { useQuery } from 'villus';
import each from 'lodash-es/each';
import { useStore } from '@/store/Store';

const useUi = () => {
    const store = useStore();
    const variables = computed(() => ({ lang: store.lang.value }));
    const { data, isDone } = useQuery({
        variables,
        query: `
        query FetchEntries($lang: [String]) {
            globalSet(handle: "ui", site: $lang) {
                ... on ui_GlobalSet { globaldUi { ... on globaldUiBlock_Entry { key, val } } }
            }
        }
    `,
    });

    watch(isDone, () => {
        if (!isDone.value) return;
        const ui = {};
        each(data.value.globalSet.globaldUi, (d) => {
            ui[d.key] = d.val;
        });
        store.ui.value = ui;
    });
};

export default useUi;
