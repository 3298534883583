<script setup>
import { ref, watch, nextTick, computed, onMounted } from 'vue';

import CloseX from '@/icons/close-x.svg';
import Caret from '@/icons/caret.svg';
import Check from '@/icons/check.svg';
import Corner from '@/icons/corner.svg';

const props = defineProps({
    options: {
        type: Object,
        required: true,
        default: () => {},
    },
    label: {
        type: String,
        default: 'Sélectionnez une option',
    },
    defaultValue: {
        type: Array,
        required: false,
        default: () => [],
    },
    tabindex: {
        type: Number,
        required: false,
        default: 0,
    },
    preselectedIds: {
        type: Array,
        required: false,
        default: () => [],
    },
    colors: {
        type: Object,
        required: false,
        default: () => ({
            bg: 'dark-blue',
            text: 'white',
        }),
    },
    allowReset: {
        type: Boolean,
        default: true,
    },
    side: {
        type: String,
        default: 'left',
    },
});

const emit = defineEmits(['beforeReset', 'reset', 'input', 'initialInput']);
const selected = ref([]);
const button = ref(null);
const wrapper = ref(null);
const animated = ref(false);
selected.value = props.defaultValue ? props.defaultValue : [];
const open = ref(true);
const rootEl = ref(null);

const wrapperLarger = ref(true);
const wrapperLargerOrEqual = ref(true);
const wrapperLargerLessThanCorners = ref(false);

const valueIsDefault = computed(() => JSON.stringify(selected.value) === JSON.stringify(props.defaultValue));

const calculateWidths = () => {
    if (!wrapper.value.$el) return;
    // +14 = arbitrary value for rounded corner adjustmeent
    const diff = wrapper.value.$el.getBoundingClientRect().width - button.value.getBoundingClientRect().width;
    wrapperLargerLessThanCorners.value = diff < 14 && diff > 0;
    wrapperLarger.value = wrapper.value.$el.getBoundingClientRect().width > button.value.getBoundingClientRect().width;
    wrapperLargerOrEqual.value = wrapper.value.$el.getBoundingClientRect().width >= button.value.getBoundingClientRect().width;
};

onMounted(() => {
    for (let i = 0, il = props.preselectedIds.length; i < il; ++i) {
        const preselectedId = props.preselectedIds[i];
        const found = Object.keys(props.options).includes(preselectedId);
        if (found) {
            selected.value.push(preselectedId);
        }
    }

    calculateWidths();
    open.value = false;
    nextTick(() => {
        animated.value = true;
    });

    emit('initialInput', selected.value);
});

const resetOptions = () => {
    emit('beforeReset', selected.value);
    selected.value = props.defaultValue;
    emit('reset');
};

const selectOption = (option) => {
    if (selected.value.includes(option)) {
        selected.value = selected.value.filter((item) => option !== item);
    } else {
        selected.value.push(option);
    }
    emit('input', selected.value);
};
</script>

<template>
    <div
        ref="rootEl"
        v-click-outside="
            () => {
                open = false;
            }
        "
        class="DropdownCheckboxes relative inline-flex h-12 text-left leading-none outline-none"
        :tabindex="tabindex"
        :class="{
            'opacity-0': !animated,
            'opacity-100': animated,
        }"
    >
        <div
            ref="button"
            class="DropdownCheckboxes__selected align-center relative z-2 flex w-full cursor-pointer items-center font-medium transition-all duration-500 md:w-auto"
            :class="[
                'bg-' + colors.bg,
                'text-' + colors.text,
                { open: open, 'rounded-xl': !open, 'rounded-t-xl': open, 'rounded-br-xl': animated && !wrapperLargerOrEqual },
            ]"
        >
            <span
                class="DropdownCheckboxes__selected-title flex h-full w-full items-center whitespace-nowrap pl-5 pr-3 hover:bg-white/10 md:w-auto"
                v-on:click="open = !open"
            >
                <span class="mr-auto">{{ label }}</span>
                <span
                    class="DropdownCheckboxes__count leading-2 ml-4 inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-full text-xs"
                    :class="['text-' + colors.bg, 'bg-' + colors.text, { 'opacity-100': selected.length > 0, 'opacity-0': selected.length <= 0 }]"
                >
                    <span>{{ selected.length }}</span>
                </span>
            </span>

            <button
                v-if="!allowReset || valueIsDefault"
                class="DropdownCheckboxes__icon flex h-full w-8 items-center justify-center pr-1 hover:bg-white/10"
                v-on:click="open = !open"
            >
                <Caret
                    class="DropdownCheckboxes__arrow pointer-events-none h-auto w-1.5 fill-current transition"
                    :class="['text-' + colors.text, { '-rotate-90': open, 'rotate-90': !open }]"
                />
            </button>

            <button
                v-if="allowReset && !valueIsDefault"
                class="DropdownCheckboxes__icon flex h-full w-8 items-center justify-center pr-1 hover:bg-white/10"
                v-on:click="resetOptions()"
            >
                <CloseX
                    class="DropdownCheckboxes__arrow pointer-events-none h-auto w-2 fill-current transition"
                    :class="['text-' + colors.text]"
                />
            </button>

            <Corner
                class="absolute bottom-0 right-0 h-auto w-2 translate-x-[99%] fill-current text-dark-blue opacity-0 transition duration-500"
                :class="{
                    'opacity-100 delay-200': animated && open && wrapperLarger,
                }"
            />
        </div>

        <transition
            enter-active-class="transition-[max-height,border-radius] duration-500"
            leave-active-class="transition-[max-height,border-radius] duration-500"
            enter-from-class="!max-h-0"
            enter-to-class="!max-h-59"
            leave-from-class="!max-h-59"
            leave-to-class="!max-h-0"
            mode="out-in"
            :css="animated"
        >
            <transition-group
                v-show="open"
                ref="wrapper"
                class="DropdownCheckboxes__items absolute top-full z-1 max-h-59 w-fit min-w-full overflow-scroll rounded-b-xl"
                :class="[
                    'bg-' + colors.bg,
                    'text-' + colors.text,
                    { 'rounded-tr-xl': animated && wrapperLarger, 'left-0': side === 'left', 'right-0': side === 'right' },
                ]"
                tag="div"
                enter-active-class="transition duration-500"
                leave-active-class="transition duration-500"
                enter-from-class="translate-x-2 opacity-0"
                enter-to-class="translate-x-0 opacity-100"
                leave-from-class="translate-x-0 opacity-100"
                leave-to-class="translate-x-2 opacity-0"
                mode="out-in"
                v-on:wheel.stop
                v-on:scroll.stop
            >
                <div
                    v-for="(option, i) in options"
                    v-show="open"
                    :key="i + option"
                    class="DropdownCheckboxes__item-wrapper flex cursor-pointer select-none items-center whitespace-nowrap px-4 leading-[2.25] transition hover:bg-white/10"
                    :class="['text-' + colors.text]"
                    :style="{
                        paddingRight: wrapperLargerLessThanCorners ? '30px' : null,
                        transitionDelay: Math.min(i * 25, 250) + 'ms',
                    }"
                    v-on:click="selectOption(i)"
                >
                    <span class="mr-2 inline-flex h-4 w-4 shrink-0 items-center justify-center rounded-sm bg-white">
                        <Check
                            v-show="selected.includes(i)"
                            class="h-auto w-2.5 fill-current"
                            :class="['text-' + colors.bg]"
                        />
                    </span>
                    <span>{{ option }}</span>
                </div>
            </transition-group>
        </transition>
    </div>
</template>
