<script setup>
import { ref, computed, nextTick, watch, onMounted } from 'vue';
import { useQuery } from 'villus';
import { useStore } from '@/store/Store.js';
import Cookies from 'js-cookie';
import { useRecaptchaProvider, useChallengeV3 } from 'vue-recaptcha';

import { GA } from '@/mixins/Ga';

const errorEmail = ref(false);
const email = ref([]);
const first_name = ref([]);
const last_name = ref([]);
const success = ref(false);
const errorMsg = ref(false);
const store = useStore();

const props = defineProps({
    textClasses: {
        type: String,
        default: 'text-darker-blue',
    },
    btnClasses: {
        type: String,
        default: 'bg-darker-blue',
    },
    iconClasses: {
        type: String,
        default: 'text-white',
    },
});

// Initialize reCAPTCHA
useRecaptchaProvider({
    language: 'fr',
});
const { execute } = useChallengeV3('newsletter_submit');

const variables = computed(() => ({ lang: store.lang.value }));
const { data } = await useQuery({
    variables,
    query: `query FetchNewsletterMailchimp($lang: [String]) {
        infos: globalSet(handle: "footer", site: $lang) {
            ... on footer_GlobalSet {
                input: globalsFooterInput,
                first_name: globalsFooterFirstName,
                last_name: globalsFooterLastName,
                thanks: globalsFooterThanks,
            }
        }
    }`,
});

const ga = new GA({ category: 'footerMenu', action: 'clicInterne' });

const send = async () => {
    errorEmail.value = !/\S+@\S+\.\S+/.test(email.value);

    if (errorEmail.value) return;

    try {
        // Execute reCAPTCHA v3 and get token
        const recaptchaToken = await execute();

        const res = await fetch(
            `/mailchimp?email=${email.value}&first_name=${first_name.value}&last_name=${last_name.value}&recaptcha_token=${recaptchaToken}`,
        );
        const json = await res.json();

        /**
         * @todo:   Vérifier si json.status donne autre chose qu'une booléenne au succès
         *          et adapter le code pour que le code dans la condition if(json.status)
         *          s'exécute quand même si c'est le cas
         */
        success.value = json.status;

        // console.log(json.status);

        if (json.status || json.error == 400) {
            success.value = true;
            ga.send({ label: 'newsletterSuccess' });
            Cookies.set('newsletter_subscribed', true, { expires: 730 }); // 2 years
        } else {
            /**
             * @todo: Message d'erreur provenant d'un nouveau champ du bloc
             */
            errorMsg.value = true;
        }
    } catch (error) {
        console.error('reCAPTCHA or submission error:', error);
        errorMsg.value = true;
    }
};
</script>
<template>
    <div
        v-if="success"
        class="Footer__thanks bg-main-dark inset-0 flex items-center"
    >
        <h6 class="h6">
            {{ data.infos.thanks }}
        </h6>
    </div>
    <div
        v-else
        class="flex w-full flex-wrap md:max-w-120"
        :class="[textClasses]"
    >
        <div
            v-if="errorMsg"
            class="mb-4 w-full text-dark-red"
        >
            Une erreur est survenue
        </div>
        <div
            class="Footer__input-container Footer__input-half Footer__input-first relative mb-5 flex w-66 items-center border-b-2 border-current pb-4 transition duration-300 xl:mr-4 xl:w-[calc(50%-0.5rem)]"
        >
            <input
                v-model="first_name"
                class="Footer__input w-full border-none bg-transparent font-sans font-normal leading-loose transition duration-300 placeholder:text-current placeholder:transition placeholder:focus:opacity-50"
                :placeholder="data.infos.first_name"
                v-on:keyup.enter="send"
            />
        </div>
        <div
            class="Footer__input-container Footer__input-half relative mb-5 flex w-66 items-center border-b-2 border-current pb-4 transition duration-300 xl:w-[calc(50%-0.5rem)]"
        >
            <input
                v-model="last_name"
                class="Footer__input w-full border-none bg-transparent font-sans font-normal leading-loose transition duration-300 placeholder:text-current placeholder:transition placeholder:focus:opacity-50"
                :placeholder="data.infos.last_name"
                v-on:keyup.enter="send"
            />
        </div>
        <div
            class="Footer__input-container relative mb-5 flex w-66 items-center border-b-2 border-current pb-4 transition duration-300 xl:w-full"
            :class="{ 'is-error border-red': errorEmail }"
        >
            <input
                v-model="email"
                class="Footer__input w-full border-none bg-transparent font-sans font-normal leading-loose transition duration-300 placeholder:text-current placeholder:transition placeholder:focus:opacity-50"
                :class="{ 'text-dark-red placeholder:text-dark-red': errorEmail }"
                :placeholder="data.infos.input"
                v-on:keyup.enter="send"
            />
            <div
                class="Footer__submit group box-border flex size-9 shrink-0 grow-0 cursor-pointer rounded-full bg-current p-2 transition"
                :class="[btnClasses]"
                v-on:click="send"
            >
                <Icon
                    type="arrow"
                    :svgclass="['block fill-current transition', iconClasses]"
                />
            </div>
        </div>
    </div>
</template>
