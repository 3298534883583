<script setup>
import CircleType from 'circletype';
import { onUnmounted, ref, computed, nextTick, watch, onMounted } from 'vue';
import { gsap } from 'gsap';
import { useRoute } from 'vue-router';

import { ScrollScene } from '@/commons/js/ScrollScene';
import { GA } from '@/mixins/Ga';
import { useStore } from '@/store/Store.js';

const emit = defineEmits(['openPopup']);

const store = useStore();
const el = ref(null);
const textTop = ref(null);
const textBottom = ref(null);
const textContainer = ref(null);
const route = useRoute();
const garbage = [];
const garbageWatchers = [];
const pageScrolled = ref(false);
const mouseOver = ref(false);
const rootEl = ref(null);
const icon = ref('');
const ga = new GA();
const isMini = computed(() => (pageScrolled.value && !mouseOver.value) || (route.meta.name !== 'home' && store.md.value) || store.xs.value);
const inited = ref(false);
const shouldStop = ref(false);
const loaded = ref(false);
// icon.value = store.md.value ? 'dollar' : 'rabais'
icon.value = 'mail';

onMounted(() => {
    nextTick(() => {
        const r = (75 * rootEl.value.offsetWidth) / 150;
        new CircleType(textTop.value).radius(r);
        new CircleType(textBottom.value).radius(r);
        inited.value = true;

        /**
         * Detect when we are insie the page content
         */
        const scene = new ScrollScene({
            triggerElement: '.Page',
            triggerHook: 0,
            offset: 100,
        });

        scene.Scene.on('enter', () => {
            pageScrolled.value = true;
        });
        scene.Scene.on('leave', () => {
            pageScrolled.value = false;
        });

        garbage.push(scene);

        /**
         * Detect when we are reaching Footer
         */
        const pin = new ScrollScene({
            triggerHook: 1,
            triggerElement: '.Footer',
            offset: 150,
        });
        const alphaValue = store.md.value || store.xs.value ? 1 : 0;

        pin.Scene.on('enter', () => {
            gsap.to(rootEl.value, { autoAlpha: alphaValue });
        });
        pin.Scene.on('leave', () => {
            gsap.to(rootEl.value, { autoAlpha: 1 });
        });
        garbage.push(pin);

        /**
         * Wiggle animation
         */
        const topLetters = textTop.value.querySelectorAll('span');
        for (let i = 0, il = topLetters.length; i < il; ++i) {
            const topLetter = topLetters[i];
            topLetter.innerHTML = `<span style="display:block;" class="inner">${topLetter.innerHTML}</span>`;
        }
        const bottomLetters = textBottom.value.querySelectorAll('span');
        for (let i = 0, il = bottomLetters.length; i < il; ++i) {
            const bottomLetter = bottomLetters[i];
            bottomLetter.innerHTML = `<span style="display:block;" class="inner">${bottomLetter.innerHTML}</span>`;
        }
        const lettersTl = gsap.timeline({
            repeat: -1,
            repeatDelay: 8,
            delay: 5,
            onRepeat() {
                // We only want to pause the animation at the end of it, not in the middle of it
                if (shouldStop.value) {
                    this.pause();
                }
            },
        });
        const sp = 0.04;
        let tt = -10;
        if (store.md.value) {
            tt = -7;
        } else if (store.xl.value) {
            tt = -15;
        }

        const offsetFactor = (rootEl.value.querySelectorAll('.inner').length * sp).toFixed(6);
        lettersTl.to(rootEl.value.querySelectorAll('.inner'), { y: tt, stagger: sp });
        lettersTl.to(rootEl.value.querySelectorAll('.inner'), { y: 0, stagger: sp }, `-=${offsetFactor}`);
        lettersTl.to(rootEl.value.querySelectorAll('.Icon'), { rotate: 8, duration: 0.1 }, 1);
        lettersTl.to(rootEl.value.querySelectorAll('.Icon'), { rotate: -8, duration: 0.1 }, 1.1);
        lettersTl.to(rootEl.value.querySelectorAll('.Icon'), { rotate: 8, duration: 0.1 }, 1.2);
        lettersTl.to(rootEl.value.querySelectorAll('.Icon'), { rotate: -8, duration: 0.1 }, 1.3);
        lettersTl.to(rootEl.value.querySelectorAll('.Icon'), { rotate: 0 }, 1.4);

        // Watch for changes in isMini to pause the animation only at the end of it
        // Garbage collect to unwatch when unmounted
        garbageWatchers.push(
            watch(isMini, (val) => {
                if (val) {
                    shouldStop.value = true;
                } else {
                    shouldStop.value = false;
                    lettersTl.resume();
                }
            }),
        );
    });

    loaded.value = true;
});

onUnmounted(() => {
    while (garbage.length) {
        garbage.pop().destroy();
    }

    while (garbageWatchers.length) {
        garbageWatchers.pop()();
    }
});

const openPopup = () => {
    store.showPopupNewsletter.value = true;
    emit('openPopup');
};
</script>

<template>
    <button
        ref="rootEl"
        class="FindBio fixed z-40 flex items-center justify-center text-base transition-[opacity,bottom,right,width,height] duration-1000 print:hidden"
        :class="[
            'is-' + $route.meta.name,
            !isMini ? 'text-light-' + store.headerColor.mainColor : null,
            {
                'bottom-0 right-0 h-30 w-30': isMini,
                'bottom-4 right-6 h-34 w-34 xl:h-38 xl:w-38': !isMini,
                'text-white': isMini,
                'opacity-0': !loaded,
                'opactiy-100': loaded,
            },
            !isMini && $route.meta.name == 'home' ? '!text-white' : null,
        ]"
        v-on:click="
            ga.send({ category: 'siteWide', action: 'clicInterne', label: 'newsletterCta' });
            openPopup();
        "
        v-on:mouseenter="mouseOver = true"
        v-on:mouseleave="mouseOver = false"
    >
        <div
            ref="textContainer"
            class="FindBio__text-container absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 transform-gpu transition duration-1000"
            :class="{
                'rotate-45 scale-60 opacity-0': isMini,
                'opacity-0': !inited,
                '-rotate-45 opacity-100': inited && !isMini,
            }"
        >
            <div
                ref="textTop"
                class="FindBio__text is-top absolute left-1/2 top-0"
            >
                {{ ui.find_bio }}
            </div>
            <div
                ref="textBottom"
                class="FindBio__text is-bottom absolute bottom-0 left-1/2 rotate-180"
            >
                {{ ui.find_bio }}
            </div>
        </div>

        <div
            class="FindBio__circle box-content flex h-11 w-11 items-center justify-center rounded-full border-2 transition duration-1000 xl:h-22 xl:w-22"
            :class="[
                {
                    'scale-70 transform-gpu': isMini,
                    'bg-white': $route.meta.name == 'location' && isMini,
                    'opacity-0': !inited,
                    'opactiy-100': inited,
                },
                isMini ? 'border-bright-' + store.headerColor.mainColor : 'border-light-' + store.headerColor.mainColor,
                isMini ? 'bg-dark-' + store.headerColor.mainColor : null,
                $route.meta.name == 'location' && isMini ? 'text-dark-' + store.headerColor.mainColor : null,
                !isMini && $route.meta.name == 'home' ? '!border-white' : null,
            ]"
        >
            <div
                class="FindBio__icon transition-[transform] duration-1000"
                :class="{
                    'scale-130': isMini,
                }"
            >
                <Icon
                    :type="icon"
                    svgclass="fill-current h-[1.35em] xl:h-[2em] w-auto"
                />
            </div>
        </div>
    </button>
</template>
